import React, { useContext, useEffect } from "react";

import SplashPage from "../components/SplashPage";


const Index = ({}) => {


  // @jinny - feel free to make a separate component but it seems we currently want to show the same splash screen before and after login?  
  return <SplashPage />;
};

export default Index;
