import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Card = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background-color: #f9f9f9; /* Card background color */
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensure content is distributed evenly */
  align-items: center;
  height: 100%; /* Ensures all cards are the same height */

  &:hover .overlay {
    opacity: 0.3; /* Show overlay on hover */
  }

  img {
    width: 100%;
    height: auto;
    max-height: 200px; /* Limit image height */
    object-fit: cover; /* Ensure the image covers the available space */
    flex-grow: 1; /* Allow image to take available space */
  }

  .content {
    width: 100%;
    padding: 10px;
    text-align: center;
    background-color: #f9f9f9; /* Same color as card background */
  }

  .content h3 {
    margin: 5px 0;
    font-size: 14px;
    color: #333; /* Dark grey text color */
    text-decoration: underline;
    text-decoration-color: #333; /* Match underline color with text color */
  }

  .content p {
    margin: 5px 0;
    font-size: 14px;
    color: #333; /* Dark grey text color */
  }

  .tag {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: white;
    color: black;
    padding: 5px;
    font-size: 12px;
    font-weight: bold;
  }
`;

// Styled overlay for the hover effect
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: orange; /* Light orange overlay */
  opacity: 0; /* Start hidden */
  transition: opacity 0.3s ease; /* Smooth transition */
  pointer-events: none; /* Allow clicks to pass through */
`;

const ItemCard = ({ item }) => {
  return (
    <Card>
      <Link to={`/item/${item.purchase_id}`} state={{ item, from: "/closet" }}>
        {item.source === "EMAIL" && (
          <div className="tag">imported from email</div>
        )}
        <img src={item.image_url} alt={item.name} />
        <Overlay className="overlay" />
        <div className="content">
          <h3>{item.name}</h3>
          <p>{item.brand}</p>
        </div>
      </Link>
    </Card>
  );
};

export default ItemCard;
