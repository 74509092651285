// src/components/OutfitCreation.js

import React, { useState } from 'react';
import styled from 'styled-components';

const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
`;

const CarouselItem = styled.div`
  text-align: center;
  width: 200px;
`;

const ArrowButton = styled.button`
  margin: 0 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  color: #333;

  &:hover {
    color: #007bff;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
`;

const ItemName = styled.p`
  margin-top: 10px;
  font-size: 16px;
  color: #333;
`;

const OutfitCreation = ({ tops, bottoms, shoes }) => {
  const [topIndex, setTopIndex] = useState(0);
  const [bottomIndex, setBottomIndex] = useState(0);
  const [shoesIndex, setShoesIndex] = useState(0);

  const handleNext = (setter, items, index) => {
    setter((index + 1) % items.length);
  };

  const handlePrev = (setter, items, index) => {
    setter((index - 1 + items.length) % items.length);
  };

  return (
    <div>
      {/* Top/Dress Carousel */}
      <CarouselContainer>
        <h2>Top / Dress</h2>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ArrowButton onClick={() => handlePrev(setTopIndex, tops, topIndex)}>
            &#8592;
          </ArrowButton>
          <CarouselItem>
            <Image src={tops[topIndex].item_image_url} alt={tops[topIndex].item_name} />
            <ItemName>{tops[topIndex].item_name}</ItemName>
          </CarouselItem>
          <ArrowButton onClick={() => handleNext(setTopIndex, tops, topIndex)}>
            &#8594;
          </ArrowButton>
        </div>
      </CarouselContainer>

      {/* Bottom Carousel */}
      <CarouselContainer>
        <h2>Bottom</h2>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ArrowButton onClick={() => handlePrev(setBottomIndex, bottoms, bottomIndex)}>
            &#8592;
          </ArrowButton>
          <CarouselItem>
            <Image src={bottoms[bottomIndex].item_image_url} alt={bottoms[bottomIndex].item_name} />
            <ItemName>{bottoms[bottomIndex].item_name}</ItemName>
          </CarouselItem>
          <ArrowButton onClick={() => handleNext(setBottomIndex, bottoms, bottomIndex)}>
            &#8594;
          </ArrowButton>
        </div>
      </CarouselContainer>

      {/* Shoes Carousel */}
      <CarouselContainer>
        <h2>Shoes</h2>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ArrowButton onClick={() => handlePrev(setShoesIndex, shoes, shoesIndex)}>
            &#8592;
          </ArrowButton>
          <CarouselItem>
            <Image src={shoes[shoesIndex].item_image_url} alt={shoes[shoesIndex].item_name} />
            <ItemName>{shoes[shoesIndex].item_name}</ItemName>
          </CarouselItem>
          <ArrowButton onClick={() => handleNext(setShoesIndex, shoes, shoesIndex)}>
            &#8594;
          </ArrowButton>
        </div>
      </CarouselContainer>
    </div>
  );
};

export default OutfitCreation;