import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import shibaImage from '../assets/404.jpg';

const Container = styled.div`
  text-align: center;
  padding: 20px;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin: 20px 0;
`;

const BackLink = styled(Link)`
  margin-top: 20px;
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;

  &:hover {
    background-color: #0056b3;
  }
`;

const NotFoundPage = () => {
  return (
    <Container>
      <Image src={shibaImage} alt="404 Not Found" />
      <Title>Oops! The page you're looking for does not exist.</Title>
      <BackLink to="/">Go Back Home</BackLink>
    </Container>
  );
};

export default NotFoundPage;