import React, { useState, useEffect, useContext } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useNavigationType,
} from "react-router-dom";
import styled from "styled-components";
import { useToast } from "./Toast";
import { AuthContext } from "../context/AuthProvider";
import { ItemsContext } from "../context/ItemsContext";

const DetailContainer = styled.div`
  padding: 20px 40px; /* Adds padding to the entire page */
  font-family: "Arial", sans-serif;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto; /* Centers content on the page */
  gap: 40px;

  @media (min-width: 768px) {
    flex-direction: row; /* Arrange content horizontally for larger screens */
    align-items: flex-start;
    gap: 20px;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  max-width: 400px; /* Restricts the image container width */
  text-align: center; /* Centers the image for smaller screens */
`;

const ItemImage = styled.img`
  width: 100%;
  max-width: 350px; /* Maximum image width */
  height: auto;
  border-radius: 8px; /* Slightly rounded corners for the image */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
`;

const TextContainer = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  h1 {
    font-size: 24px;
    color: #333;
  }

  p {
    font-size: 16px;
    color: #555;
    margin: 5px 0;
  }

  p strong {
    font-weight: bold;
    color: #333;
  }
`;

const BackButton = styled.button`
  display: inline-block;
  margin-bottom: 20px;
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #0056b3;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;

const EditButton = styled.button`
  padding: 10px 20px;
  border: none;
  background-color: #ffc107;
  color: white;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #e0a800;
  }
`;

const DeleteButton = styled.button`
  padding: 10px 20px;
  border: none;
  background-color: #dc3545;
  color: white;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #c82333;
  }
`;

const SaveButton = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #218838;
  }
`;
const CancelButton = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #6c757d;
  color: white;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #5a6268;
  }
`;

const InputField = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 100%;
`;
const SelectField = styled.select`
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 100%;
`;

// Chip (tag) styles
const Chip = styled.div`
  display: inline-block;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  color: #333;
  position: relative;
  cursor: default;
`;
const ChipClose = styled.span`
  position: absolute;
  top: -6px;
  right: -6px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 12px;
  cursor: pointer;
`;

const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const VisitStoreButton = styled.a`
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    background-color: #0056b3;
    background-color: #c82333;
  }
`;

const ItemDetail = () => {
  const location = useLocation();
  const [canGoBack, setCanGoBack] = useState(false);
  const navigationType = useNavigationType();
  const navigate = useNavigate();
  const { item: initialItem } = location.state || {};
  const { item_purchase_id } = useParams();
  const [item, setItem] = useState(initialItem);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const { items, setItems } = useContext(ItemsContext);
  const { userkey } = useContext(AuthContext);
  const { showToast } = useToast();

  const [isEditing, setIsEditing] = useState(false);
  const [itemName, setItemName] = useState(item ? item.name : "");
  const [itemBrand, setItemBrand] = useState(item ? item.brand : "");
  const [itemImageUrl, setItemImageUrl] = useState(
    item ? item.image_url : ""
  );
  const [itemStoreUrl, setItemStoreUrl] = useState(
    item ? item.store_url : ""
  );
  const [itemColor, setItemColor] = useState(item ? item.color : "");
  const [itemType, setItemType] = useState(item ? item.item_type : "");
  const [itemSubcategories, setItemSubcategories] = useState(
    item && item.item_subcategory ? item.item_subcategory.split(",") : []
  );
  const [newSubcategory, setNewSubcategory] = useState("");

  // Define possible options for the item type
  const itemTypeOptions = ["top", "bottom", "dress", "shoes", "accessories"];

  useEffect(() => {
    // Determine if back button should be displayed
    const fromRoute = location.state?.from; // Access the `from` route passed in state
    if (fromRoute === "/closet") {
      setCanGoBack(true); // Show back button if coming from Closet
    } else {
      setCanGoBack(false); // Hide back button otherwise. eg. when a user hits the URL directly OR when a successful add item redirect
    }
  }, [location.state]);

  // Fallback API call to fetch item details if item is not available in location.state
  useEffect(() => {
    if (!initialItem && userkey && item_purchase_id) {
      console.log(item);
      const fetchItem = async () => {
        try {
          setLoading(true);
          const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/user/${userkey}/item/${item_purchase_id}`
          );
          const data = await response.json();
          if (response.ok) {
            setItem(data);
          } else {
            throw new Error("Failed to fetch item.");
          }
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };

      fetchItem();
    }
  }, [initialItem, userkey, item_purchase_id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!item) {
    return <div>Item not found</div>;
  }

  const handleSave = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/item_purchases/${item.purchase_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer your_token",
          },
          body: JSON.stringify({
            name: itemName,
            brand: itemBrand,
            color: itemColor,
            item_type: itemType || "top", // hack to set default to top in case itemType is null
            image_url: itemImageUrl,
            store_url: itemStoreUrl,
            item_subcategory: itemSubcategories.join(","),
          }),
        }
      );

      if (response.ok) {
        const updatedItem = {
          ...item,
          item_name: itemName,
          item_brand: itemBrand,
          item_color: itemColor,
          item_type: itemType,
          item_image_url: itemImageUrl,
          item_store_url: itemStoreUrl,
          item_subcategory: itemSubcategories.join(","),
        };

        setItem(updatedItem);

        const updatedItems = items.map((i) => {
          return i.purchase_id === item.purchase_id ? updatedItem : i;
        });
        setItems(updatedItems);

        showToast(`Successfully updated ${itemName}.`);
        setIsEditing(false); // Toggle back to view mode
      } else {
        showToast("Failed to update the item.", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      showToast("An error occurred while updating the item.", "error");
    }
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };
  // Handle removing a subcategory
  const handleRemoveSubcategory = (subcategoryToRemove) => {
    setItemSubcategories(
      itemSubcategories.filter((sub) => sub !== subcategoryToRemove)
    );
  };
  // Handle adding a new subcategory
  const handleAddSubcategory = (e) => {
    if (e.key === "Enter" && newSubcategory.trim() !== "") {
      setItemSubcategories([...itemSubcategories, newSubcategory.trim()]);
      setNewSubcategory(""); // Clear the input after adding
    }
  };
  const handleDelete = async () => {
    console.log(JSON.stringify(item, null, 2));
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/item_purchases/${item.purchase_id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json", // Optional headers
            Authorization: "Bearer your_token", // Optional headers
          },
        }
      );
      if (response.ok) {
        console.log(`Item ${item.item_name} deleted successfully.`);
        const updatedItems = items.filter((i) => i.item_id !== item.item_id);
        setItems(updatedItems);
        showToast(`Successfully deleted ${item.item_name} from your closet.`);
        navigate("/closet"); // Redirect to the closet after successful delete
      } else {
        console.error("Failed to delete the item.");
        showToast("Failed to delete the item.", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      showToast("An error occurred while deleting the item.", "error");
    }
  };

  return (
    <DetailContainer>
      {canGoBack && <BackButton onClick={() => navigate(-1)}>Back</BackButton>}
      <ContentWrapper>
        <ImageContainer>
          <ItemImage src={item.image_url} alt={item.name} />
        </ImageContainer>
        <TextContainer>
          {isEditing ? (
            <>
              Name:{" "}
              <InputField
                value={itemName}
                onChange={(e) => setItemName(e.target.value)}
                placeholder="Item Name"
              />
              Brand:{" "}
              <InputField
                value={itemBrand}
                onChange={(e) => setItemBrand(e.target.value)}
                placeholder="Item Brand"
              />
              Color:{" "}
              <InputField
                value={itemColor}
                onChange={(e) => setItemColor(e.target.value)}
                placeholder="Item Color"
              />
              Image url:{" "}
              <InputField
                value={itemImageUrl}
                onChange={(e) => setItemImageUrl(e.target.value)}
                placeholder="Item Image URL"
              />
              Store site:{" "}
              <InputField
                value={itemStoreUrl}
                onChange={(e) => setItemStoreUrl(e.target.value)}
                placeholder="Store URL"
              />
              Item type:
              <SelectField
                value={itemType}
                onChange={(e) => setItemType(e.target.value)}
              >
                {itemTypeOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </SelectField>
              {/* Chips for Subcategories */}
              <ChipContainer>
                {itemSubcategories.map((subcategory) => (
                  <Chip key={subcategory}>
                    {subcategory}
                    <ChipClose
                      onClick={() => handleRemoveSubcategory(subcategory)}
                    >
                      x
                    </ChipClose>
                  </Chip>
                ))}
                <InputField
                  value={newSubcategory}
                  onChange={(e) => setNewSubcategory(e.target.value)}
                  onKeyDown={handleAddSubcategory}
                  placeholder="Add Subcategory and press Enter"
                />
              </ChipContainer>
              <SaveButton onClick={handleSave}>Save Changes</SaveButton>
              <CancelButton onClick={handleEditToggle}>Cancel</CancelButton>
            </>
          ) : (
            <>
              <h1>{item.name}</h1>
              {item.brand && (
                <p>
                  <strong>Brand:</strong> {item.brand}
                </p>
              )}
              <p>
                <strong>Category:</strong> {item.item_type}
              </p>

              {item.color && (
                <p>
                  <strong>Color:</strong> {item.color}
                </p>
              )}
              {item.size && (
                <p>
                  <strong>Size:</strong> {item.size}
                </p>
              )}
              {item.notes && (
                <p>
                  <strong>Notes:</strong> {item.notes}
                </p>
              )}
              {/* View-only subcategories as chips */}
              <ChipContainer>
                {itemSubcategories.map((subcategory) => (
                  <Chip key={subcategory}>{subcategory}</Chip>
                ))}
              </ChipContainer>

              <ButtonGroup>
                <EditButton onClick={handleEditToggle}>Edit Item</EditButton>
                <DeleteButton onClick={handleDelete}>
                  Delete from Closet
                </DeleteButton>
              </ButtonGroup>
            </>
          )}
        </TextContainer>
      </ContentWrapper>
    </DetailContainer>
  );
};

export default ItemDetail;
