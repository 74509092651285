import React, { useState, useContext } from "react";
import styled from "styled-components";
import SearchItemCard from "../components/SearchItemCard";
import { AuthContext } from "../context/AuthProvider";
import { useToast } from "../components/Toast";
import { ItemsContext } from "../context/ItemsContext";
import { Link, useNavigate } from "react-router-dom";

const PageContainer = styled.div`
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
`;

const BackButton = styled(Link)`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  text-decoration: none; /* Remove the underline from the link */
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const SearchSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const SearchBar = styled.input`
  width: 80%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const UploadButton = styled.button`
  padding: 10px 20px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: grey;
  }
`;

const UploadSection = styled.div`
  padding: 20px;
  background-color: #f5f5f5;
  border: 2px dashed #ccc;
  text-align: center;
  margin-bottom: 20px;
`;

const PhotoGuideSection = styled.div`
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-bottom: 20px;
`;

const PhotoGuideTitle = styled.h2`
  font-size: 18px;
  margin-bottom: 20px;
`;

const PhotoGuideImages = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GuideImage = styled.img`
  width: 30%;
  border-radius: 5px;
`;

const GuideDescription = styled.p`
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
`;

const ItemGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  padding: 10px;

  @media (min-width: 900px) {
    grid-template-columns: repeat(
      3,
      minmax(200px, 1fr)
    ); /* 3 items per row minimum */
  }

  @media (min-width: 600px) and (max-width: 899px) {
    grid-template-columns: repeat(
      2,
      minmax(200px, 1fr)
    ); /* 2 items per row minimum */
  }

  @media (max-width: 599px) {
    grid-template-columns: repeat(
      1,
      minmax(200px, 1fr)
    ); /* 1 item per row for very small screens */
  }
`;

const AddItemPage = () => {
  const { userkey } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [addedItems, setAddedItems] = useState([]); // Track added items by their IDs
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const { items, setItems } = useContext(ItemsContext);
  const { showToast } = useToast();
  const navigate = useNavigate();

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchKeyDown = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setIsSearching(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/search?q=${encodeURIComponent(searchTerm)}&userkey=${encodeURIComponent(userkey)}`
        );
        const data = await response.json();
        setSearchResults(data);
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    }
  };

  const handleAddToCloset = async (item) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/item_purchases/new`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ item_id: item.id, userkey: userkey }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log(item); // this contains actual item data
        console.log(data); // this contains purchase data
        console.log(items); // this contains current items in the closet
        setItems([
          ...items,
          {
            item_id: item.id,
            item_name: item.name,
            item_brand: item.brand,
            item_color: item.color,
            item_image_url: item.image_url,
            item_store_url: item.store_url,
            item_type: item.item_type,
            item_subcategory: item.item_subcategory,
            purchase_id: data.id,
            purchase_price: data.purchase_price,
            purchased_from: data.purchased_from,
            purchased_on: data.purchased_on,
            size: data.size,
            notes: data.notes,
            source: data.source,
          },
        ]);
        setAddedItems((prevAddedItems) => [...prevAddedItems, item.id]);
        showToast(`Successfully added ${item.name} to closet!`);
      } else {
        console.error("Failed to add item to closet.");
      }
    } catch (error) {
      console.error("Error adding item to closet:", error);
    }
  };

  const MAX_FILE_SIZE_MB = 5; // Maximum file size in MB
  const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024; // Convert to bytes
  const MAX_FILES = 10; // Maximum number of files

  const handleUploadClick = async () => {
    // Create a hidden file input element
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/png, image/jpeg, image/heic, image/heif"; // Accept JPEG, PNG, HEIC
    input.multiple = true;

    input.onchange = async (event) => {
      const files = event.target.files;

      // Check file count
      if (files.length > MAX_FILES) {
        alert(`You can upload a maximum of ${MAX_FILES} files.`);
        return;
      }

      if (!files || files.length === 0) {
        console.log("No files selected");
        return;
      }

      // Validate files
      const invalidFiles = [];
      const validFiles = [];
      for (const file of files) {
        if (file.size > MAX_FILE_SIZE) {
          invalidFiles.push(file);
        } else if (
          !["image/jpeg", "image/png", "image/heic", "image/heif"].includes(
            file.type
          )
        ) {
          invalidFiles.push(file);
        } else {
          validFiles.push(file);
        }
      }

      // Handle invalid files
      if (invalidFiles.length > 0) {
        const invalidFileNames = invalidFiles
          .map((file) => file.name)
          .join(", ");
        alert(
          `The following files are invalid (either not JPG/PNG/HEIC or exceed ${MAX_FILE_SIZE_MB}MB):\n${invalidFileNames}`
        );
        return;
      }

      if (validFiles.length === 0) {
        alert("No valid files selected for upload.");
        return;
      }

      // Prepare files for upload
      const formData = new FormData();
      validFiles.forEach((file) => formData.append("files", file));

      try {
        // Send files to the API
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/upload`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (!response.ok) {
          alert("Failed to upload the files.");
          return;
        }

        const data = await response.json();

        // Navigate to the new item page with API response as state
        navigate("/item/new", { state: { results: data } });
      } catch (error) {
        console.error("Upload failed:", error);
        alert("An error occurred during file upload. Please try again.");
      }
    };

    // Trigger the file picker dialog
    input.click();
  };

  return (
    <PageContainer>
      <Header>
        <Title>Add items to Cello Closet</Title>
        <BackButton to="/closet">Go back</BackButton>
      </Header>

      <SearchSection>
        <SearchBar
          type="text"
          placeholder="Search items or paste link"
          value={searchTerm}
          onChange={handleSearchChange}
          onKeyDown={handleSearchKeyDown}
        />
        <UploadButton onClick={handleUploadClick}>Upload</UploadButton>
      </SearchSection>

      {isSearching && searchResults.length > 0 ? (
        <ItemGrid>
          {searchResults.map((item) => (
            <SearchItemCard
              key={item.id}
              item={item}
              isAdded={addedItems.includes(item.id)}
              onAddToCloset={handleAddToCloset}
            />
          ))}
        </ItemGrid>
      ) : (
        <>
          <UploadSection>
            <p>Drag and drop or browse files to upload</p>
            <p>Upload limit: 10 photos</p>
          </UploadSection>

          <PhotoGuideSection>
            <PhotoGuideTitle>How to take photos</PhotoGuideTitle>
            <PhotoGuideImages>
              <div>
                <GuideImage
                  src="https://res.cloudinary.com/flagshipcloud/image/upload/v1724710420/app-assets/ajui9o1txj4p2riipbvg.png"
                  alt="Guide 1"
                />
                <GuideDescription>
                  Take a photo or screenshot against a solid background color.
                </GuideDescription>
              </div>
              <div>
                <GuideImage
                  src="https://res.cloudinary.com/flagshipcloud/image/upload/v1724710420/app-assets/ajui9o1txj4p2riipbvg.png"
                  alt="Guide 2"
                />
                <GuideDescription>
                  Cello automatically tags your photo and removes backgrounds.
                </GuideDescription>
              </div>
              <div>
                <GuideImage
                  src="https://res.cloudinary.com/flagshipcloud/image/upload/v1724710420/app-assets/ajui9o1txj4p2riipbvg.png"
                  alt="Guide 3"
                />
                <GuideDescription>
                  Cello then finds the best fit product for your upload.
                </GuideDescription>
              </div>
            </PhotoGuideImages>
          </PhotoGuideSection>
        </>
      )}
    </PageContainer>
  );
};

export default AddItemPage;
