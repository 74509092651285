import React, { useState } from "react";
import styled from "styled-components";
import ItemCard from "../components/ItemCard";
import { useNavigate } from "react-router-dom";

const ClosetWrapper = styled.div`
  display: flex;
  padding: 20px;
`;

const SidebarContainer = styled.div`
  width: 180px;
  background-color: #ffffff; /* Neutral background */
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start; /* Align sidebar items to the left */
  margin-top: 20px; /* Align with the search bar */
  border-radius: 10px;
  font-family: 'Arial', sans-serif; /* Modern font */
`;

const SearchInput = styled.input`
  width: 50%; /* Set a shorter width for the search bar */
  box-sizing: border-box;
  padding: 10px 15px; /* Adjust padding for balance */
  border: 1px solid #ccc;
  border-radius: 8px; /* Smoother corners */
  outline: none;
  font-size: 12px;
  color: #333;

  &:focus {
    border-color: #0056b3; /* Sleek blue border on focus */
    box-shadow: 0 0 5px rgba(0, 86, 179, 0.2); /* Subtle glow */
  }
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: flex-start; /* Align items to the left */
  gap: 10px; /* Add spacing between buttons */
`;

const CategoryButton = styled.button`
  width: 100%; /* Full width of the sidebar */
  padding: 10px 10px;
  border: none;
  background-color: ${({ selected }) => (selected ? "#000000" : "white")};
  color: ${({ selected }) => (selected ? "white" : "black")};
  cursor: pointer;
  text-align: left; /* Align text to the left */
  font-size: 14px;
  border-radius: 8px; /* Smooth corners */

  &:hover {
    background-color: grey;
    color: white;
  }

  &:active {
    background-color: #0056b3;
  }
`;

const MainContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Spread items across the container */
  align-items: center; /* Align items vertically */
  padding: 0px 10px 20px; /* Top alignment with zero margin on top */
  gap: 10px; /* Space between search bar and button */
  width: 100%;
`;

const AddItemButton = styled.button`
  padding: 10px 20px;
  border: none;
  background-color: #28a745;
  color: white;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: #218838;
  }
`;

const ItemGrid = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  padding: 10px;

  @media (min-width: 900px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (min-width: 600px) and (max-width: 899px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 599px) {
    grid-template-columns: 1fr;
  }

  > div {
    max-height: 400px;
    overflow: hidden;
  }
`;

const categories = ["all", "top", "bottom", "dress", "shoes", "accessories"];

const Closet = ({ items }) => {
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredItems = items.filter((item) => {
    const matchesCategory =
      selectedCategory === "all" || item.item_type?.toLowerCase() === selectedCategory;

    const matchesSearchQuery =
      item.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.brand?.toLowerCase().includes(searchQuery.toLowerCase());

    return matchesCategory && matchesSearchQuery;
  });

  return (
    <ClosetWrapper>
      <SidebarContainer>
        <FilterContainer>
          {categories.map((category) => (
            <CategoryButton
              key={category}
              onClick={() => handleCategoryClick(category)}
              selected={selectedCategory === category}
            >
              {category}
            </CategoryButton>
          ))}
        </FilterContainer>
      </SidebarContainer>

      <MainContentContainer>
        <h1>My Wardrobe</h1>
        <ControlsContainer>
          <SearchInput
            type="text"
            placeholder="Search your closet..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <AddItemButton onClick={() => navigate("/item/add")}>
            Add Items
          </AddItemButton>
        </ControlsContainer>
        <ItemGrid>
          {filteredItems.map((item) => (
            <ItemCard key={item.purchase_id} item={item} />
          ))}
        </ItemGrid>
      </MainContentContainer>
    </ClosetWrapper>
  );
};

export default Closet;
