import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Closet from "../components/Closet";
import { ItemsContext } from "../context/ItemsContext";

import styled from "styled-components";

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  position: fixed; /* Fixed position to occupy the whole screen */
  top: 0;
  left: 0;
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Optional: Add a light overlay */
  z-index: 1000; /* Ensure it appears above other content */
  font-size: 24px; /* Adjust as needed */
  color: #333; /* Adjust as needed */
`;

const Admin = ({}) => {
  const { userkey } = useParams();
  const { items, setItems, loading, setLoading, error, setError } =
    useContext(ItemsContext);

  useEffect(() => {
    if (userkey && items.length === 0) {
      console.log(userkey);
      // Fetch data from the API
      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/items/${userkey}`
          );
          const data = await response.json();
          if (data.error === "User not found") {
            setItems([]);
          } else {
            setItems(data);
          }
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [userkey, items.length, setItems, setLoading, setError]);

  if (loading) {
    return <LoadingContainer>Loading...</LoadingContainer>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (items.length === 0) {
    return <div>User not found</div>;
  }

  return <Closet items={items} />;
};

export default Admin;
