import React, { useContext, useState, useEffect } from "react";

import Closet from "../components/Closet";
import { AuthContext } from "../context/AuthProvider";
import { ItemsContext } from "../context/ItemsContext";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import SplashPage from "../components/SplashPage";

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  position: fixed; /* Fixed position to occupy the whole screen */
  top: 0;
  left: 0;
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Optional: Add a light overlay */
  z-index: 1000; /* Ensure it appears above other content */
  font-size: 24px; /* Adjust as needed */
  color: #333; /* Adjust as needed */
`;

const Index = ({}) => {
  const { isLoggedIn, userkey, signOut } = useContext(AuthContext);
  const { items, setItems, loading, setLoading, error, setError } =
    useContext(ItemsContext);
  const location = useLocation();

  // we can remove items.length from the dependency array and from the if statement to refresh every time the user navigates to the home page
  // inefficient but also a way to ensure the data is always up to date
  // we are currently doing manual data updates in the NewItem.js file
  useEffect(() => {
    console.log(`Index.js useEffect ${isLoggedIn} ${userkey} ${items.length}`);
    if (isLoggedIn && userkey && items.length === 0) {
      console.log(userkey);
      // Fetch data from the API
      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/api/items/${userkey}`
          );
          const data = await response.json();
          console.log(data);
          if (data.error) {
            setError(data.error);
          } else {
            setItems(data);
          }
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [isLoggedIn, userkey, location.pathname, items.length, setItems, setLoading, setError]);

  if (!isLoggedIn) {
    return <SplashPage />;
  }

  if (loading) {
    return <LoadingContainer>Loading...</LoadingContainer>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return <Closet items={items} />;
 
};

export default Index;
