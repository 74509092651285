// src/routes/NewOutfit.js
import React, { useContext, useState, useEffect } from "react";

import { AuthContext } from "../context/AuthProvider";
import { ItemsContext } from "../context/ItemsContext";
import { useLocation } from "react-router-dom";
import OutfitCreation from '../components/OutfitCreation';

// Sample data for each category (tops, bottoms, shoes)
// const tops = [
//   { id: 1, name: 'Red Shirt', image: '/images/red-shirt.jpg' },
//   { id: 2, name: 'Blue Blouse', image: '/images/blue-blouse.jpg' },
//   // Add more top items here
// ];

// const bottoms = [
//   { id: 1, name: 'Jeans', image: '/images/jeans.jpg' },
//   { id: 2, name: 'Black Skirt', image: '/images/black-skirt.jpg' },
//   // Add more bottom items here
// ];

// const shoes = [
//   { id: 1, name: 'Sneakers', image: '/images/sneakers.jpg' },
//   { id: 2, name: 'Heels', image: '/images/heels.jpg' },
//   // Add more shoe items here
// ];

const NewOutfit = () => {
  const { userkey } = useContext(AuthContext);
  const { items, setItems, loading, setLoading, error, setError } =
    useContext(ItemsContext);

  const [categories, setCategories] = useState({ tops: [], bottoms: [], shoes: [] });
  const location = useLocation();
  // console.log("NewOutfit route rendered", { userkey, items, categories });

  useEffect(() => {
    if (!userkey || items.length > 0) return; // Avoid fetch if items already loaded

    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/items/${userkey}`
        );
        const data = await response.json();

        if (data.error) {
          setError(data.error);
        } else {
          // Batch updates to reduce renders
          setItems(data);
          setCategories({
            tops: data.filter((item) => item.item_type === "Top"),
            bottoms: data.filter((item) => item.item_type === "Bottom"),
            shoes: data.filter((item) => item.item_type === "Shoes"),
          });
        }
      } catch (err) {
        setError(err.message || "An error occurred while fetching items.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userkey, location.pathname]);

  if (items.length === 0 || loading) {
    return <div>Loading...</div>;
  }
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h1>Create Your Outfit</h1>
      <OutfitCreation
        tops={categories.tops}
        bottoms={categories.bottoms}
        shoes={categories.shoes}
      />
    </div>
  );
};

export default NewOutfit;