// src/routes/StyleGuide.js
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";

import AddButton from "../components/AddButton";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthProvider";

const mockFolders = ["Casual - Fall", "Work", "Japan Trip"];
// const mockOutfits = [
//   {
//     id: 1,
//     image: "path/to/image1.jpg",
//     tags: ["#School", "#Casual"],
//     items: [
//       { name: "White sleeveless top", link: "/item/1" },
//       { name: "Blue skirt", link: "/item/2" },
//       { name: "Sneakers", link: "/item/3" },
//     ],
//   },
//   {
//     id: 2,
//     image: "path/to/image2.jpg",
//     tags: ["#Casual", "#DateNight"],
//     items: [
//       { name: "Black crop top", link: "/item/4" },
//       { name: "White pants", link: "/item/5" },
//       { name: "Sandals", link: "/item/6" },
//     ],
//   },
//   // Add more mock outfits as needed
// ];

// Styled components for layout and styling
const Container = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  font-family: Arial, sans-serif;
`;

const Title = styled.h1`
  font-size: 2em;
  margin-bottom: 1em;
  text-align: center;
`;

const FolderTabs = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
`;

const FolderTab = styled.button`
  padding: 10px 20px;
  background-color: ${({ selected }) => (selected ? "#ffd966" : "#f0f0f0")};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    background-color: #ffd966;
  }
`;

const AddFolderButton = styled(FolderTab)`
  background-color: #ddd;
  color: #888;
  cursor: not-allowed;
`;

const OutfitGrid = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
`;

const OutfitCard = styled.div`
  width: 250px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  text-align: center;
  background-color: #fff;
`;

const OutfitImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
`;

const Tags = styled.div`
  margin: 10px 0;
  font-size: 0.9em;
  color: #666;
`;

const ItemLinks = styled.div`
  font-size: 0.9em;
  color: #007bff;
  text-align: left;
  padding-top: 10px;

  a {
    color: #007bff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyleGuide = () => {
  const { userkey } = useContext(AuthContext);
  const navigate = useNavigate();
  const [selectedFolder, setSelectedFolder] = useState(mockFolders[0]);
  const [outfits, setOutfits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch outfits from the API
    const fetchOutfits = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/outfits/${userkey}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch outfits");
        }
        const data = await response.json();
        // Assume data has folders and outfits, adjust accordingly
        // setFolders(data.folders || []);
        setOutfits(data || []);
        // setSelectedFolder(data.folders ? data.folders[0] : null);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (userkey) {
      fetchOutfits();
    }
  }, [userkey]);

  const handleAddOutfitClick = () => {
    navigate("/outfit/new"); // Navigate to the new item creation page
  };

  return (
    <Container>
      <Title>Get Dressed!</Title>
      <AddButton onClick={handleAddOutfitClick}>Add Outfit</AddButton>

      {error && <p style={{ color: "red" }}>{error}</p>}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          {/* Folder Tabs */}
          <FolderTabs>
            {mockFolders.map((folder) => (
              <FolderTab
                key={folder}
                selected={folder === selectedFolder}
                onClick={() => setSelectedFolder(folder)}
              >
                {folder}
              </FolderTab>
            ))}
            <AddFolderButton disabled>+ Add new folder</AddFolderButton>
          </FolderTabs>

          {/* Outfit Grid */}
          <OutfitGrid>
            {outfits.map((outfit) => (
              <OutfitCard key={outfit.id}>
                <OutfitImage
                  src={outfit.image_url}
                  alt={`Outfit ${outfit.id}`}
                />
                {outfit.tags && outfit.tags.length > 0 && (
                  <Tags>Tags: {outfit.tags.join(", ")}</Tags>
                )}
                <ItemLinks>
                  <strong>In this outfit:</strong>
                  <ul>
                    {outfit.item_purchases.map((item) => (
                      <li key={item.item_id}>
                        <a
                          href={`/item/${item.item_id}`}
                          // target="_blank"
                          // rel="noopener noreferrer"
                        >
                          {item.item_name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </ItemLinks>
              </OutfitCard>
            ))}
          </OutfitGrid>
        </>
      )}
    </Container>
  );
};

export default StyleGuide;
