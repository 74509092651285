import React, { createContext, useState } from 'react';

export const ItemsContext = createContext();

export const ItemsProvider = ({ children }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const reloadItems = async (userkey) => {
    if (!userkey) {
      console.warn("No userkey provided for fetching items.");
      return;
    }

    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/items/${userkey}`
      );
      if (!response.ok) throw new Error("Failed to fetch items.");
      const updatedItems = await response.json();
      setItems(updatedItems);
      console.log("Items successfully reloaded.");
    } catch (err) {
      console.error("Error reloading items:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const clearItems = () => {
    setItems([]);
  };

  return (
    <ItemsContext.Provider value={{ items, setItems, loading, setLoading, error, setError, reloadItems, clearItems }}>
      {children}
    </ItemsContext.Provider>
  );
};
